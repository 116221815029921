<template>
  <VApp id="staff-calendar" :class="'calendar-container navbar-offset-tablet h-auto'">
    <div class="px-1-5 pt-1 salon-flow">
      <div class="row fill-height">
        <div class="col">
          <CalendarNav
            :template-type="'staff'"
            :type="type"
            :staff-list="staffList"
            :title="title"
            :date="today"
            @prev="prev"
            @next="next"
            @set-type="setType"
          />

          <div v-if="type === 'week'" class="scrollbar" @scroll="scrollFakeBar($event)">
            <div class="child"></div>
          </div>

          <VSheet
            class="bottom-row calandar-container calendar-root"
            :class="`${type}`"
            @scroll="scrollCalendar($event)"
          >
            <VCalendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :type="type"
              :start="today"
              :short-weekdays="false"
              :event-height="0"
              :show-interval-label="showIntervalLabel"
              :first-interval="firstInterval"
              :interval-count="countInterval"
              :interval-format="intervalFormat"
              :interval-minutes="15"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              :interval-height="32"
              :event-start="'start_time'"
              :event-end="'end_time'"
              :event-overlap-mode="'column'"
              @click:event="showEvent"
              @change="updateRange"
            >
              <template slot="interval" slot-scope="day">
                <button class="free-time-block position-absolute w-100 h-100" @click="toggleMenu($event, day)"></button>
              </template>

              <!-- EVENT TIME SCALE COMPONENT -->
              <template #day-body="{ date, week }">
                <div
                  v-if="today === now"
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY }"
                >
                  <span v-if="cal" class="current-time-value text-primary font-weight-semibold">
                    {{ cal.times.now.time }}
                  </span>
                </div>
              </template>

              <template slot="day-header" slot-scope="{ date }">
                <div class="v-calendar-daily_head-day-label d-block">
                  <span class="day-number text-center">
                    {{ date | headerDateDayFormat }}
                  </span>
                </div>
              </template>

              <template slot="event" slot-scope="{ event }">
                <div :title="event.name" :class="event.isWeekend ? 'weekend' : 'event'" class="h-100">
                  <!-- EVENT COMPONENT -->
                  <Event
                    v-if="event.id"
                    :event="event"
                    :class="{ allSalonEvent: event.allSalonEvent }"
                    @preview-boooking-by-id="previewBoookingById"
                  />

                  <!-- EVENT STAFF DAY OFF COMPONENT -->
                  <EventWeekend v-if="event.isWeekend" />
                </div>
              </template>
            </VCalendar>
          </VSheet>
        </div>
      </div>
    </div>

    <b-modal
      id="dayoff-modal"
      ref="dayoffModal"
      body-class="filterModalBody px-1-5 px-sm-4 pb-4"
      footer-class="pt-2 px-0 d-block"
      hide-header="true"
      hide-footer="true"
      centered
    >
      <CreateDayoffForm ref="dayoffForm" :calendar-type="type" />
    </b-modal>

    <b-modal
      id="booking-view-modal"
      ref="bookingViewModal"
      header-class="modal-header p-0 border-bottom-0"
      body-class="filterModalBody pb-1 px-0"
      hide-footer
      size="lg"
      centered
    >
      <template v-slot:modal-header="{ close }">
        <div class="top-block w-100">
          <div class="d-flex pt-1-5 justify-content-between">
            <div class="w-100 d-flex justify-content-around"></div>
            <font-awesome-icon
              :icon="['far', 'times']"
              class="icon-close-mobile text-gray mr-1-5"
              @click="close()"
            ></font-awesome-icon>
          </div>
        </div>
      </template>

      <div class="col px-0">
        <BookingPreview :booking-id="previewBookingId" />
      </div>
    </b-modal>

    <ClientAndBookingCreateSection />

    <VMenu
      v-model="showMenu"
      top
      :position-x="x"
      :position-y="y"
      offset-y
      style="max-width: 160px"
      :close-on-click="false"
    >
      <b-button variant="link" class="close-menu-icon bg-white p-0 border-0" @click="showMenu = false">
        <font-awesome-icon :icon="['fas', 'times-circle']" class="text-gray" style="font-size:24px"></font-awesome-icon>
      </b-button>
      <div class="d-flex flex-column btns-block">
        <b-button v-if="type === 'week'" variant="black" class="text-left text-small py-2 py-1-1" @click="viewDay()">
          <font-awesome-icon :icon="['fas', 'calendar-day']" class="text-white mr-2"> </font-awesome-icon>
          {{ $t('salon-calendar.interval_menu.day_view') }}
        </b-button>
        <b-button
          v-if="showCreateDaysOffBtn()"
          variant="black"
          class="mt-0-5 text-left text-small py-2 py-1-1"
          @click="showDayOffModal()"
        >
          <font-awesome-icon :icon="['fas', 'ban']" class="text-white mr-2"> </font-awesome-icon>
          {{ $t('salon-calendar.interval_menu.block_time_slot') }}
        </b-button>
        <b-button
          v-if="showCreateBookingBtn()"
          variant="primary"
          class="mt-0-5 text-left text-small h-auto py-2 py-1-1"
          @click="showCreateBookingModal()"
        >
          <font-awesome-icon :icon="['fas', 'user-circle']" class="text-white mr-2"> </font-awesome-icon>
          {{ $t('salon-calendar.interval_menu.create_new_booking') }}
        </b-button>
      </div>
    </VMenu>
  </VApp>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import moment from 'moment'
import 'vue-select/dist/vue-select.css'
import { VSheet, VCalendar, VMenu, VApp } from 'vuetify/lib'

import { eventHub } from '@/_utils/eventhub'

import CalendarNav from './components/CalendarNav.vue'
import HeaderRowStaff from './components/HeaderRowStaff.vue'
import BodyColumnsStaff from './components/BodyColumnsStaff.vue'
import Event from './components/Event.vue'
import EventCount from './components/EventCount'
import EventWeekend from './components/EventWeekend'

import CalendarMixin from './mixins/Calendar.vue'
import CreateDayoffForm from './components/CreateDayoffForm.vue'

import ClientAndBookingCreateSection from './components/ClientAndBookingCreateSection'
import BookingPreview from './components/BookingPreview.vue'

export default {
  components: {
    VSheet,
    VCalendar,
    VMenu,
    VApp,

    CalendarNav,
    Event,
    EventWeekend,
    CreateDayoffForm,
    ClientAndBookingCreateSection,
    BookingPreview
  },
  mixins: [CalendarMixin],
  data: () => ({
    type: 'week',
    choosenTimeData: {}
  }),
  watch: {
    selectedStaff: {
      deep: true,
      handler(newVal, oldVal) {
        if (
          (newVal.staff_id && newVal.staff_id !== oldVal.staff_id) ||
          (newVal.manager_id && newVal.manager_id !== oldVal.manager_id)
        ) {
          this.setStaffDayOff(newVal)
          this.fetchCalendarData(this.type, this.start.date)
        } else if (newVal.staff_id === 0) {
          this.redirectToAll()
        }
      }
    },
    start: {
      deep: true,
      handler(newVal) {
        this.setStaffDayOff(this.selectedStaff)
      }
    }
  },
  computed: {
    ...mapGetters({
      eventsByStaffID: 'calendar/getEventsByStaffID',
      accountInfo: 'user/getAccountInfo',
      staffList: 'salonsStaff/getStaffList',
      workDays: 'workDays/getWorkDays',
      storeStaff: 'calendar/getSelectedStaff',
      storeDate: 'calendar/getCalendarDate',
      userRole: 'user/getRole',
      getStaffServices: 'salonsStaff/getStaffServices',
      dayoffs: 'dayoffs/getDayOffs'
    }),
    events() {
      if (this.selectedStaff.staff_id || this.selectedStaff.manager_id) {
        return this.eventsByStaffID(this.selectedStaff.staff_id || this.selectedStaff.manager_id)
      }

      return []
    },
    selectedStaff: {
      get() {
        return this.storeStaff
      },
      set(val) {
        this.setSelectedStaff(val)
      }
    },
    staffListWithAllEmployee() {
      return this.staffList.concat({
        fullName: this.$t('custom-select-values.all-employee'),
        avatar: null,
        staff_id: 0
      })
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      fetchCalendarBookingsByStaffAndPeriod: 'calendar/getCalendarBookingsByStaffAndPeriod',
      fetchCalendarDayOffsByStaffAndPeriod: 'calendar/getCalendarDayOffsByStaffAndPeriod',
      fetchCalendarBookingManagerDaysoff: 'calendar/getCalendarBookingManagerDaysoff',
      fetchStaffListBySalonId: 'salonsStaff/getStaffListBySalonId',
      fetchWorkDays: 'workDays/getWorkDays',
      fetchStaffProfileInfo: 'salonsStaff/getStaffServicesInfo',
      setError: 'userMessages/setError'
    }),
    ...mapMutations({
      setSelectedStaff: 'calendar/SET_SELECTED_STAFF',
      setChoosenDayData: 'calendar/SET_CHOOSEN_DAY_DATA',
      setChoosenTypeAndTime: 'calendar/SET_CALENDAR_CHOOSEN_TIME',
      setChoosenEmptyDayData: 'calendar/SET_CHOOSEN_DAY_DATA'
    }),
    setType(type) {
      if (this.type === type) {
        return
      }

      let now = moment()
      let monday = now.clone().weekday(1)
      let date = monday.format('YYYY-MM-DD')

      if (type !== 'week') {
        date = now.format('YYYY-MM-DD')
      }

      if (this.$route.path !== `/calendar/staff/${type}/${date}` && this.$route.name === 'calendar-staff') {
        this.$router.push(`/calendar/staff/${type}/${date}`)
      }
    },
    redirectToAll() {
      const type = 'day'
      const date = this.$route.params.date

      this.$router.push(`/calendar/${type}/${date}`)
    },
    async fetchCalendarData(type, date) {
      eventHub.$emit('before-request')

      await Promise.all([
        this.fetchWorkDays(this.accountInfo.salon_id).then(() => {
          if (this.type === 'day') {
            this.calcOpenAndClosedTImeForDay(
              moment(date)
                .locale('En')
                .format('dddd')
            )
          } else {
            this.calcOpenAndClosedTImeForWeek()
          }
        }),
        this.fetchStaffListBySalonId(this.accountInfo.salon_id)
      ])

      this.updateSelectedStaff()

      let params = {
        day: date,
        period: type
      }

      if (this.selectedStaff && this.selectedStaff.staff_id) {
        params = {
          ...params,
          ...{ salon_staff_id: this.selectedStaff.staff_id }
        }

        await Promise.all([
          this.fetchCalendarBookingsByStaffAndPeriod(params),
          this.fetchCalendarDayOffsByStaffAndPeriod(params)
        ])
      } else if (this.selectedStaff && this.selectedStaff.manager_id) {
        params = {
          ...params,
          ...{ manager_id: this.selectedStaff.manager_id }
        }

        await this.fetchCalendarBookingManagerDaysoff(params)
      }

      eventHub.$emit('after-response-without-timer')

      return true
    },
    updateSelectedStaff() {
      this.staffList.findIndex(staff => {
        if (
          (staff.staff_id && this.selectedStaff.staff_id && staff.staff_id === this.selectedStaff.staff_id) ||
          (staff.manager_id && this.selectedStaff.manager_id && staff.manager_id === this.selectedStaff.manager_id)
        ) {
          this.selectedStaff = {
            ...staff
          }
        }
      })
    },
    setStaffDayOff(staff) {
      if (staff) {
        this.choosenTimeData = {}
        this.choosenTimeData.date = this.start.date
        this.choosenTimeData.type = 'day'
        this.setChoosenTypeAndTime(this.choosenTimeData)
      }
    },
    showCreateBookingModal() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false

      const { date, time } = this.selectedInterval

      this.emptyDateChoosenData.staff = this.selectedStaff
      this.emptyDateChoosenData.date = date
      this.emptyDateChoosenData.start_time = time

      this.setChoosenDayData(this.emptyDateChoosenData)
      this.$bvModal.show('client-info-modal')
    },
    showCreateBookingBtn() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false
      if (!this.selectedInterval) return false

      return this.showCreateDaysOffBtn() && this.selectedStaff.staff_id
    },
    showCreateDaysOffBtn() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false
      if (!this.selectedInterval) return false

      const now = moment().format('YYYY-MM-DD HH:mm')
      const { date, time } = this.selectedInterval

      return moment(`${date} ${time}`).diff(now, 'minutes') > 0
    },
    showDayOffModal() {
      if (!this.actionAccessRoles.includes(this.userRole)) return false
      if (!this.selectedInterval) return false
      const { date, time } = this.selectedInterval

      this.emptyDateChoosenData.staff = this.selectedStaff
      this.emptyDateChoosenData.date = date
      this.emptyDateChoosenData.start_time = time

      this.setChoosenDayData(this.emptyDateChoosenData)
      this.$bvModal.show('dayoff-modal')
    },
    viewDay() {
      if (!this.selectedInterval) return false

      const { date } = this.selectedInterval

      this.$router.push({
        name: 'calendar-staff',
        params: { type: 'day', date: date }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
